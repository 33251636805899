import React from 'react';
import Container from './common/Container';
import Section from './common/Section';
import InformationSection from './InformationSection';
import PageTitle from './PageTitle';

const Contact = () => {
  return (
    <>
      <PageTitle
        title="Agencias"
        subtitle="“Comunícate con nosotros y recibe la mejor atención personalizada”"
      />
      <Section cssStyle="section bg-white">
        <Container>
          <div className="columns">
            <div className="column is-12">
              <p className="pb-4">
                En Instabus te atenderemos con mucho gusto, puedes llamarnos a
                nuestra central de atención al cliente 01 423-6338, escribirnos
                a <a href="mailto:info@instabus.com.pe">info@instabus.com.pe</a>{' '}
                o al 965 762 927 (WhatsApp) y aclararemos todas tus dudas.
              </p>
              <p>
                También puedes comunicarte a cualquiera de nuestras agencias en
                Lima, Norte Chico, Norte y Sur Chico. En Instabus estamos atentos a escucharte y brindarte el mejor servicio.
              </p>
            </div>
          </div>

          <div className="columns mt-4">
            <div className="column is-8">
              <h4 className="subtitle is-4">Agencias</h4>
              <div className="content">
                <h5 className="subtitle is-5">Lima</h5>
                <ul>
                  <li className="mb-5">
                    <strong>Luna Pizarro</strong>
                    <br />
                    Dirección: Jr. Luna Pizarro 251 - La Victoria
                    <br />
                    Teléfono: 954158516
                  </li>
                  <li className="mb-5">
                    <strong>Plaza Norte</strong>
                    <br />
                    Dirección: Av. Gerardo Unger Nro 6911 - Independencia
                    <br />
                    Teléfono: 941748123
                  </li>
                </ul>
                <h5 className="subtitle is-5">Norte Chico</h5>
                <ul>
                  <li className="mb-5">
                    <strong>Chancay</strong>
                    <br />
                    Dirección: Av. Panamericana Norte Km 82 (Al costado del Grifo Repsol)
                    <br />
                    Teléfono: 946091927
                  </li>
                  <li className="mb-5">
                    <strong>Huacho</strong>
                    <br />
                    Dirección: Av. Panamericana Norte 1183 (Hotel Gol)
                    <br />
                    Teléfono: 940377184
                  </li>
                  <li className="mb-5">
                    <strong>Supe</strong>
                    <br />
                    Dirección: Av. Francisco Vidal 796 (Frente a la botica yosselin)
                    <br />
                    Teléfono: 940375127
                  </li>
                  <li className="mb-5">
                    <strong>Terminal Chaquila</strong>
                    <br />
                    Dirección: Prolong, Jr José Galvez 1289 (Entre antigua Panamericana norte y el Carmen)
                    <br />
                    Teléfono: 940375119
                  </li>
                  <li className="mb-5">
                    <strong>Pativilca</strong>
                    <br />
                    Dirección: Calle Bolívar 285 (A unos pasos del Museo Bolivariano)
                    <br />
                    Teléfono: 970566542
                  </li>
                  <li className="mb-5">
                    <strong>Paramonga</strong>
                    <br />
                    Dirección: Av. Francisco Vidal 921 (Frente al Grifo Repsol)
                    <br />
                    Teléfono: 940374140
                  </li>
                </ul>
                <h5 className="subtitle is-5">Norte</h5>
                <ul>
                  <li className="mb-5">
                    <strong>Huarmey</strong>
                    <br />
                    Dirección: Terminal Terrestre de Huarmey (Entre panamericana norte con av 2 de mayo)
                    <br />
                    Teléfono: 939658006 / 942393733
                  </li>
                  <li className="mb-5">
                    <strong>Casma</strong>
                    <br />
                    Dirección: Huaquilla Alta F2 -01 (Al costado de la cruz roja de Casma)
                    <br />
                    Teléfono: 928639984
                  </li>
                  <li className="mb-5">
                    <strong>Chimbote</strong>
                    <br />
                    Dirección: Carretera Panamericana Norte, Gran Trapecio Lt.1 y Lt. 2 (Terminal el Chimbador)
                    <br />
                    Teléfono: 934039338
                  </li>
                </ul>
                <h5 className="subtitle is-5">Sur Chico</h5>
                <ul>
                  <li className="mb-5">
                    <strong>Chincha</strong>
                    <br />
                    Dirección: Carretera Panamericana Sur Km 196 - N°111 interior B (Al frente de la agencia flores)
                    <br />
                    Teléfono: 971856723
                  </li>
                  <li className="mb-5">
                    <strong>Pisco</strong>
                    <br />
                    Dirección: Centro poblado Villa Los Ángeles Mz. A Lt. 8 (Cruce de Pisco)
                    <br />
                    Teléfono: 914368233
                  </li>
                  <li className="mb-5">
                    <strong>Ica</strong>
                    <br />
                    Dirección: Lambayeque 110 (Terminal Ormeño)
                    <br />
                    Teléfono: 949075589
                  </li>
                </ul>
              </div>
            </div>
            <div className="column is-4">
              <h4 className="subtitle is-4">Encuéntranos aquí</h4>
              <iframe
                title="Ubicación Instabus"
                width="100%"
                height="400"
                frameBorder="0"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3914.977698137318!2d-77.6125176485383!3d-11.115038792053527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9106df09a17ca255%3A0x380fc1f1a14c5a09!2sFrancisco%20Vidal%20921%2C%20Huacho%2015136!5e0!3m2!1sen!2spe!4v1600754711216!5m2!1sen!2spe"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </Container>
      </Section>
      <InformationSection />
    </>
  );
};

export default Contact;
